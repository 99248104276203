<template>
    <div class="modal-send-contact">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="$translate('SEND_CHECK_TITLE')" />
        </div>
        <div class="body">
            <div class="shared-contacts">
                <div class="contact-wrapper" :key="contact.id" v-for="contact in contacts">
                    <div
                        class="c-primary confirm"
                        v-show="(selectedContact || {}).id === contact.id"
                        v-html="contactConfirm"
                    />
                    <div
                        @click="onClickContact(contact)"
                        class="contact flex-row flex-between"
                        :class="{ selected: (selectedContact || {}).id === contact.id }"
                    >
                        <!--    수정시작부분   -->
                        <div class="contact-component">
                            <div
                                class="timestamp"
                                v-html="$options.filters.formatDate(contact.created_at, 'YYYY.MM.DD (HH:MM)')"
                            />
                            <div class="flex-row" v-if="contact.name">
                                <div class="name" v-html="contact.name" />
                                <div class="nickname m-l-4" v-html="`(${contact.nickname})`" />
                            </div>
                            <div class="flex-row" v-else>
                                <div class="name" v-html="contact.nickname" />
                            </div>
                        </div>
                        <i @click="onClickRemove(contact.id)" class="zmdi zmdi-close flex-wrap" />
                        <!--     수정 끝       -->
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons bottom">
            <div class="flex-row">
                <button @click="$emit('close', 0)" class="btn-default">
                    {{ 'CANCEL' | translate }}
                </button>
                <button ref="send" @click="onClickSend" class="btn-primary" :class="disabled">
                    {{ 'SEND' | translate }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import agentService from '@/services/agent'

export default {
    name: 'ModalSendCheck',
    props: ['options'],
    created() {
        this.contacts = this.options.shared_contacts || []
    },
    computed: {
        disabled() {
            return this.selectedContact === null ? 'disabled' : ''
        },
        contactConfirm() {
            return this.$translate('SEND_CHECK_CONFIRM')
                .replace(/%s/, (this.selectedContact || {}).name)
                .replace(/%s/, this.$nameOrNick(this.$store.getters.chat.user))
        },
    },
    data: () => ({
        selectedContact: null,
        contacts: null,
    }),
    methods: {
        onClickContact(contact) {
            this.selectedContact = contact
        },
        onClickSend(btn) {
            if (this.disabled) {
                this.$animate.shake(this.$refs.send)
                return
            }
            this.$emit('close', this.selectedContact)
        },
        async onClickRemove(id) {
            try {
                const { msg } = await agentService.deleteContact({
                    contact_id: Number(id),
                })
                this.$toast.success(msg, 1000)

                // 클라이언트 단에서 삭제
                this.contacts = this.contacts.filter(contact => Number(contact.id) !== Number(id))
                this.selectedContact = null
            } catch (e) {
                this.$toast.error(e.message, 1000)
            }
        },
    },
}
</script>
